/* eslint-disable @nx/enforce-module-boundaries */
import { useDisclosure } from '@mybridge/ui/hooks';
import { useRouter } from 'next/router';
import { forwardRef, useEffect } from 'react';
import { DashboardPostModal } from '../post-modal';

export const GlobalPostContainer = forwardRef(({ ...props }, ref) => {
  const { query, push } = useRouter();
  const post = query?.post;
  const postModalDisc = useDisclosure();
  useEffect(() => {
    if (post?.length) {
      postModalDisc.onOpen();
    } else {
      postModalDisc.onClose();
    }
  }, [post]);

  const handleClose = () => {
    push('/');
  };

  return (
    <>
      {post?.length > 0 ? (
        <DashboardPostModal
          {...postModalDisc}
          onClose={handleClose}
          postId={post}
        />

      ) : (
        ''
      )}
    </>
  );
});
